import React from 'react';
import PropTypes from 'prop-types';
import loadable from '../../../loadable';
import styles from './Categories.module.sass';
import Category from './category/Category';
import DROPDOWNS from '../../constants/dropdowns';

const Dropdown = loadable(() => import('../dropdown/Dropdown'));
const ShopDropdown = loadable(() => import('../dropdown/shop_dropdown/ShopDropdown'));
const AboutDropdown = loadable(() => import('../dropdown/about_dropdown/AboutDropdown'));

function Categories(props) {
  const {
    taxons, sticky, currentDropdown, onCategoryMouseOver,
    onCategoryMouseOut, onDropdownMouseOut, onDropdownMouseOver,
    shopImageLink, whiteBackground, shopImage,
  } = props;

  return (
    <div className={styles.categories}>
      <Category
        url="/shop/"
        title="Shop All"
        id={DROPDOWNS.SHOP}
        active={currentDropdown === DROPDOWNS.SHOP}
        sticky={sticky}
        onComponentMouseOver={onCategoryMouseOver}
        onComponentMouseOut={onCategoryMouseOut}
        whiteBackground={whiteBackground}
      />

      { currentDropdown === DROPDOWNS.SHOP && (
        <Dropdown
          id={DROPDOWNS.SHOP}
          onComponentMouseOver={onDropdownMouseOver}
          onComponentMouseOut={onDropdownMouseOut}
        >
          <ShopDropdown
            taxons={taxons}
            image={shopImage}
            imageLink={shopImageLink}
          />
        </Dropdown>
      )}

      <Category
        url="/shop/t/categories/featured/best-sellers/"
        title="Best Sellers"
        id={DROPDOWNS.BEST_SELLERS}
        active={currentDropdown === DROPDOWNS.BEST_SELLERS}
        sticky={sticky}
        onComponentMouseOver={onCategoryMouseOver}
        onComponentMouseOut={onCategoryMouseOut}
        whiteBackground={whiteBackground}
      />

      <Category
        url="/pages/about_us/"
        title="About Us"
        id={DROPDOWNS.ABOUT}
        active={currentDropdown === DROPDOWNS.ABOUT}
        sticky={sticky}
        onComponentMouseOver={onCategoryMouseOver}
        onComponentMouseOut={onCategoryMouseOut}
        whiteBackground={whiteBackground}
      />
      { currentDropdown === DROPDOWNS.ABOUT && (
        <Dropdown
          id={DROPDOWNS.ABOUT}
          onComponentMouseOver={onDropdownMouseOver}
          onComponentMouseOut={onDropdownMouseOut}
        >
          <AboutDropdown />
        </Dropdown>
      )}

      <Category
        url="/regimen_finder/"
        title="Skin Quiz"
        id={DROPDOWNS.REGIMEN_FINDER}
        active={currentDropdown === DROPDOWNS.REGIMEN_FINDER}
        sticky={sticky}
        onComponentMouseOver={onCategoryMouseOver}
        onComponentMouseOut={onCategoryMouseOut}
        whiteBackground={whiteBackground}
        className={styles['low-priority']}
      />
    </div>
  );
}

Categories.propTypes = {
  taxons: PropTypes.arrayOf(PropTypes.shape({})),
  sticky: PropTypes.bool.isRequired,
  currentDropdown: PropTypes.string,
  onDropdownMouseOver: PropTypes.func.isRequired,
  onDropdownMouseOut: PropTypes.func.isRequired,
  onCategoryMouseOver: PropTypes.func.isRequired,
  onCategoryMouseOut: PropTypes.func.isRequired,
  shopImageLink: PropTypes.string,
  whiteBackground: PropTypes.bool.isRequired,
  shopImage: PropTypes.string.isRequired,
};

export default Categories;
