import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '../cart/services/store';
import Header from './components/header/Header';
import { userActions } from '../user/actions';
import { loyaltyProgramActions } from '../the_lounge/actions';

window.store = store;

export default function renderHeader() {
  const elementId = 'react-header-component';
  const headerComponentElement = document.getElementById(elementId);

  const data = JSON.parse(document.querySelector(`script[data-dom-id="${elementId}"]`).textContent);

  const {
    shopImageLink, shopImage, lineItemsCount, whiteBackground, user,
    showLoyaltyProgram, tiers, pointsEarnedForYear, freeSamplesTitle,
  } = data;

  if (user) {
    store.dispatch(userActions.setUser(user));
  }

  if (tiers) {
    store.dispatch(loyaltyProgramActions.setTiers(tiers));
  }

  if (pointsEarnedForYear) {
    store.dispatch(loyaltyProgramActions.setPointsEarnedForYear(pointsEarnedForYear));
  }

  hydrateRoot(
    headerComponentElement,
    <Provider store={window.store}>
      <Header
        shopImage={shopImage}
        shopImageLink={shopImageLink}
        whiteBackground={whiteBackground}
        lineItemsCount={Number(lineItemsCount)}
        showLoyaltyProgram={showLoyaltyProgram}
        freeSamplesTitle={freeSamplesTitle}
      />
    </Provider>,
  );
}
