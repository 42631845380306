import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import closeIcon from '../../../../../images/icons/close.svg';
import styles from './AccountDropdown.module.sass';
import ClickableDiv from '../../../../ui_kit/clickable_div/ClickableDiv';
import { pointsEarnedForYearSelector, tiersSelector } from '../../../../the_lounge/selectors/loyalty_program';
import { userObjectSelector } from '../../../../user/selectors/user';
import { modalsActions } from '../../../../modals/actions';
import icon from '../../../../../images/icons/user/order-active-purple.svg';

function AccountDropdown(props) {
  const {
    logout, tiers, pointsEarnedForYear, user, onDropdownMouseOver, onDropdownMouseOut, onCloseClick,
  } = props;

  if (!user) { return null; }

  const {
    first_name: firstName,
    point_balance: pointBalance,
    loyalty_program_deactivated_at: loyaltyProgramDeactivatedAt,
    id,
  } = user;

  const eligibleTiers = JSON.parse(tiers).filter((tier) => tier.from <= pointsEarnedForYear);
  const currentTierIndex = eligibleTiers.length - 1;
  const currentTier = eligibleTiers[currentTierIndex];

  return (
    <div
      className={styles['account-dropdown']}
      onMouseOut={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          onDropdownMouseOut?.();
        }
      }}
      onBlur={onDropdownMouseOut}
      onMouseOver={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          onDropdownMouseOver?.();
        }
      }}
      onFocus={onDropdownMouseOver}
    >
      <div className={styles.content}>
        { onCloseClick && (
          <ClickableDiv
            className={styles['close-button']}
            onClick={onCloseClick}
          >
            <img src={closeIcon} alt="close icon" />
          </ClickableDiv>
        )}
        <div className={styles.username}>
          { firstName && (`Hi, ${firstName}`)}
          { !firstName && ('Hi there')}
        </div>
        <a href={`/users/${id}/rewards/`}>
          <div className={styles.category}>the lounge summary</div>
          { currentTier && (
            <>
              <div className={styles.title}>view activity and redeem rewards</div>
              <div className={`${styles.text} ${styles.statistic}`}>
                <div className={styles.row}>
                  <div className={styles.label}>current status:</div>
                  <div className={styles.value}>
                    {loyaltyProgramDeactivatedAt ? 'DEACTIVATED' : currentTier.name}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>points:</div>
                  <div className={styles.value}>
                    {loyaltyProgramDeactivatedAt ? 0 : pointBalance}
                  </div>
                </div>
              </div>
            </>
          )}
          { !currentTier && (
            <div className={styles.title}>
              sign up for the lounge rewards program and get 100 points
            </div>
          )}
        </a>
        <a href={`/users/${id}/`}>
          <div className={styles.category}>account summary</div>
        </a>
        <a href={`/users/${id}/orders/`}>
          <div className={styles.title}>orders</div>
          <div className={styles.text}>
            <img src={icon} alt="Orders" />
            view & track
          </div>
        </a>
        <a href={`/users/${id}/subscriptions/`}>
          <div className={styles.title}>subscriptions</div>
          <div className={styles.text}>
            <img src={icon} alt="Subscriptions" />
            view & edit
          </div>
        </a>
        <a href={`/users/${id}/`}>
          <div className={styles.title}>account settings</div>
          <div className={styles.text}>
            <img src={icon} alt="Account" />
            payment, address, password, etc.
          </div>
        </a>
        <a href="/quizzes/">
          <div className={styles.category}>skincare regimen quiz</div>
          <div className={styles.title}>take the quiz</div>
          <div className={styles.text}>complete for personalized recommendations</div>
        </a>
        <div className={styles.title}>
          <ClickableDiv onClick={logout}>sign out</ClickableDiv>
        </div>
      </div>
      <div className={styles.background}>
        <div className={styles.image} />
      </div>
    </div>
  );
}

AccountDropdown.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.number,
    point_balance: PropTypes.number,
    loyalty_program_deactivated_at: PropTypes.string,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  tiers: PropTypes.string.isRequired,
  pointsEarnedForYear: PropTypes.number.isRequired,
  onDropdownMouseOver: PropTypes.func,
  onDropdownMouseOut: PropTypes.func,
  onCloseClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  tiers: tiersSelector(state),
  pointsEarnedForYear: pointsEarnedForYearSelector(state),
  user: userObjectSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(modalsActions.logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDropdown);
