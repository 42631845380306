import React from 'react';
import { hydrateRoot } from 'react-dom/client';

import Footer from './components/footer/Footer';

export default function renderFooter() {
  const footerElement = document.getElementById('react-footer');

  if (!footerElement) { return; }

  hydrateRoot(
    footerElement,
    <Footer />,
  );
}
