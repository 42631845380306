import React from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.sass';

function Dropdown(props) {
  const { children, onComponentMouseOver, onComponentMouseOut } = props;

  return (
    <div
      className={styles.dropdown}
      onMouseOver={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          onComponentMouseOver?.();
        }
      }}
      onFocus={onComponentMouseOver}
      onMouseOut={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          onComponentMouseOut?.();
        }
      }}
      onBlur={onComponentMouseOut}
    >
      <div className={styles.wrapper}>
        {children}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  onComponentMouseOver: PropTypes.func,
  onComponentMouseOut: PropTypes.func,
};

export default Dropdown;
