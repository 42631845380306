import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Category.module.sass';

function Category(props) {
  const {
    id, url, title, onComponentMouseOut, active, sticky, whiteBackground, className,
    onComponentMouseOver,
  } = props;
  return (
    <div
      className={cx(
        styles.category,
        active ? styles.active : null,
        sticky ? styles.sticky : null,
        whiteBackground ? styles['white-background'] : null,
        className,
      )}
      onMouseOver={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          onComponentMouseOver(id);
        }
      }}
      onFocus={() => { onComponentMouseOver(id); }}
      onMouseOut={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          onComponentMouseOut();
        }
      }}
      onBlur={onComponentMouseOut}
    >
      <a href={url}>
        {title}
      </a>
    </div>
  );
}

Category.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onComponentMouseOver: PropTypes.func.isRequired,
  onComponentMouseOut: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  sticky: PropTypes.bool.isRequired,
  whiteBackground: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Category;
