import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Login.module.sass';
import AccountDropdown from '../../dropdown/account_dropdown/AccountDropdown';
import Dropdown from '../../dropdown/Dropdown';
import DROPDOWNS from '../../../constants/dropdowns';
import ClickableDiv from '../../../../ui_kit/clickable_div/ClickableDiv';

function Login(props) {
  const {
    user, currentDropdown, onDropdownMouseOver,
    onDropdownMouseOut, onDropdownLinkMouseOver, onDropdownLinkMouseOut,
    openLoginDialog, sticky, whiteBackground,
  } = props;
  return (
    <div className={styles.login}>
      { !user && (
        <ClickableDiv
          className={cx(
            styles.link,
            sticky ? styles.sticky : null,
            whiteBackground ? styles['white-background'] : null,
          )}
          onClick={openLoginDialog}
        >
          Sign in
        </ClickableDiv>
      )}

      { user && (
        <>
          <a
            className={cx(
              styles.link,
              sticky ? styles.sticky : null,
              whiteBackground ? styles['white-background'] : null,
            )}
            onMouseOver={(event) => {
              if (!event.currentTarget.contains(event.relatedTarget)) {
                onDropdownLinkMouseOver(DROPDOWNS.ACCOUNT);
              }
            }}
            onFocus={() => { onDropdownLinkMouseOver(DROPDOWNS.ACCOUNT); }}
            onMouseOut={onDropdownLinkMouseOut}
            onBlur={onDropdownLinkMouseOut}
            href={`/users/${user.id}/`}
          >
            { user.first_name ? `${user.first_name}'s Account` : 'My Account' }
          </a>
          { currentDropdown === DROPDOWNS.ACCOUNT && (
            <Dropdown id={DROPDOWNS.ACCOUNT}>
              <AccountDropdown
                user={user}
                onDropdownMouseOver={onDropdownMouseOver}
                onDropdownMouseOut={onDropdownMouseOut}
              />
            </Dropdown>
          )}
        </>
      )}
    </div>
  );
}

Login.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.number,
  }),
  currentDropdown: PropTypes.string,
  onDropdownMouseOver: PropTypes.func.isRequired,
  onDropdownMouseOut: PropTypes.func.isRequired,
  onDropdownLinkMouseOver: PropTypes.func.isRequired,
  onDropdownLinkMouseOut: PropTypes.func.isRequired,
  openLoginDialog: PropTypes.func.isRequired,
  sticky: PropTypes.bool.isRequired,
  whiteBackground: PropTypes.bool.isRequired,
};

export default Login;
